<template >
  <div class="table-wrap">
      <a-row :gutter="16">
        <a-col :span="16">
          <a-row>
            <a-col :span="24"  class="table-container mb-2">
                <div class="p-1 font">{{detail.title}}</div>
                <div class="px-1 font-xs text-muted">{{detail.release_time}} <span class="text-primary">{{detail.dept_name}}</span></div>

            </a-col>
            <a-col :span="24"  class="table-container">
                <div class="p-1" v-if="detail.zclx == '申报通知'">
                    <div class="titleFrame font-weight font-xs mb-1">申报条件</div>
                    <div class="rounded p-1 mb-2 border text-area">{{detail.sbtj}}</div>
                    <div class="titleFrame font-weight font-xs mb-1">扶持标准</div>
                    <div class="rounded p-1 mb-2 border text-area">{{detail.fcbz}}</div>
                    <div class="titleFrame font-weight font-xs mb-1">申报材料</div>
                    <div class="rounded p-1 mb-2 border text-area">{{detail.sbcl}}</div> 
                </div>

                <div class="p-1" v-else>
                    <div class="titleFrame font-weight font-xs mb-1">政策摘要</div>
                    <div class="rounded p-1 mb-2 border text-area">{{detail.description}}</div>
                </div>

                <div class="py-2 ml-1">
                  <a-button type="primary" v-if="detail.is_manual" @click="downloadFile()">查看原文</a-button>
                  <a-button type="primary" v-else @click="goUrl(detail)">查看原文</a-button>
                </div>
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="8" class="table-container"></a-col>
      </a-row>

      <a-modal v-model:visible="visible" :footer="null" title="文件下载" @ok="handleOk">
        <div v-for="(item,index) in detail.article_file" :key="index">
          <p><a :href="item">{{ detail.file_name[index] }}</a></p>
        </div>
        
      </a-modal>
  </div>
</template>
<script>
import {  defineComponent } from 'vue';
import service from '@/service/service';
// import { message, Modal } from 'ant-design-vue';
import { mapState } from 'vuex';

export default defineComponent({
  components: {
    
  },

  data() {
    return {
      id: "",
      detail:{
        id:"",
        title:"",
        release_time:"",
        dept_name:"",
        sbtj:"",
        fcbz:"",
        sbcl:"",
        description:"",
        url:"",
        zclx:"",
        
      },
      visible:0
    }
  },
  watch: {
  },
  mounted() {
    this.id = this.$route.query.id
    this.getDetail();
    
  },
  computed: {
    ...mapState(['userinfo']),
    
    
  },
  methods: {
    
    async getDetail() {
      let res = await service.get_article_detail({ id:this.id });
      //let industry = await service.get_item_lists();
      if(res.code == 1){
        res.data.article_file = JSON.parse(res.data.article_file)
        res.data.file_name = JSON.parse(res.data.file_name)
        this.detail = res.data
        console.log(this.detail)
      }
    },
  downloadFile(){
    
    console.log(this.visible)
      this.visible=true;
    },
   goUrl(item) {
      window.open(item.url)
      //this.$router.push('/prev/policyDetail?id='id)
    },

  },
  
})
</script>
<style lang="less" scoped>
.table-wrap {
  margin: 24px auto;
  width: 1400px;
}

.border{
  border: 1px solid #cccccc;
}
.titleFrame{
  border-left: 5px solid #3399FF; padding-left: 5px;
}
.table-container {

  .tableHeadFilter {
    .row {
      border-bottom: 1px solid #f5f6f7;
      display: flex;
      justify-content: space-between;
      align-content: center;
      padding: 12px 0;
      padding-right: 60px;
      position: relative;
      height: 69px;
      overflow: hidden;
      transition: all .25s ease;

      &.active {
        height: auto;
      }

      .fixelBtn {
        position: absolute;
        top: 12px;
        right: 12px;
        height: 44px;
        display: flex;
        align-items: center;
        width: 50px;
        justify-content: space-between;
        color: #666;
        transition: all .25s ease;
        cursor: pointer;

        &:hover {
          color: #3399FF;
        }
      }

      .label {
        flex: 0 0 auto;
        margin-right: 12px;
        font-size: 16px;
        color: #000;
        display: flex;
        align-items: center;
      }

      .filters {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;

        .filter {
          flex: 0 0 auto;
          margin: 6px 12px;
          font-size: 16px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #000;
          padding: 0 6px;
          // border-radius: 10px;
          cursor: pointer;

          &.active {
            background: @primary-color;
            color: #fff;
          }
        }
      }
    }

    .search {
      padding-top: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      .filter {
        min-width: 120px;
        margin-right: 12px;
      }

      .filter-btn {
        margin-right: 12px;

        &:last-child {
          margin: 0;
        }
      }
    }


  }
  .text-area{white-space: pre-line; word-break: break-all;}
  .data-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 500px;
    cursor: pointer;
    transition: all .25s ease;

    &:hover {
      color: #3399FF;
    }
  }


}
</style>